/** @jsxImportSource @emotion/react */
import 'twin.macro'
import {
  useAdminTransactionPayments,
  useAdminTransactionReceipts,
  useAdminTransactionReceiptUsers,
  useAdminTransactionReceiptVehicles,
} from '../../../queries'
import { ISearchParams } from '../../../types'
import { convertEURToHRK, standardizedFormatDate } from '../../../utils'
import { useSearch } from '../../common/general/hooks/useSearch'
import { PageLoader } from '../../common/general/PageLoader'
import { TableCell } from '../../common/general/table/cells'
import { CustomTable } from '../../common/general/table/CustomTable'
import { useHistory } from 'react-router-dom'
import { SelectField } from '../../common/general/fields/SelectField'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { TransactionType } from './TransactionType'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateInput } from '../../common/general/fields/DateInput'
import dayjs from 'dayjs'
import { useSearchQueryParams } from '../../common/general/hooks/useSearchQueryParams'
import { useEffect } from 'react'

interface IAdminTransactionFiltersForm {
  type: TransactionType
  userId?: number
  vehicleRegistration?: string
  dateFrom: Date
  dateTo: Date
}

export const AdminTransactionsTablePage = () => {
  const initialSearchParams: Partial<ISearchParams> = {
    orderField: 'receipt.createdAt',
    order: 'DESC',
  }
  const [searchParams, { setPage }] = useSearch(initialSearchParams)
  const [formSearchParams, setSearchParams] = useSearchQueryParams<any>()
  const { data: receiptData, isLoading: receiptIsLoading } =
    useAdminTransactionReceipts({ ...searchParams, ...formSearchParams })
  const { data: paymentData, isLoading: paymentIsLoading } =
    useAdminTransactionPayments({ ...searchParams, ...formSearchParams })
  const { data: receiptUsersData } = useAdminTransactionReceiptUsers()
  const { data: receiptVehiclesData } = useAdminTransactionReceiptVehicles()
  const history = useHistory()

  const schema = yup.object().shape({
    type: yup.string().oneOf(Object.values(TransactionType)).required(),
    userId: yup.number().min(1).optional(),
    vehicleRegistration: yup.string().optional(),
    dateFrom: yup.date(),
    dateTo: yup.date(),
    paymentType: yup.string().optional(),
  })

  const defaultValues = {
    type: TransactionType.RECEIPT,
    dateTo: dayjs().toDate(),
    dateFrom: dayjs().subtract(1, 'month').toDate(),
  }

  const methods = useForm<IAdminTransactionFiltersForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    shouldUseNativeValidation: false,
    mode: 'onBlur',
  })

  const type = methods.watch('type')
  const dateFrom = methods.watch('dateFrom')
  const dateTo = methods.watch('dateTo')
  const userId = methods.watch('userId')
  const vehicleRegistration = methods.watch('vehicleRegistration')
  //@ts-ignore
  const paymentType = methods.watch('paymentType')

  useEffect(() => {
    setSearchParams({
      type,
      dateFrom,
      dateTo,
      userId,
      vehicleRegistration,
      paymentType,
    })
  }, [type, dateFrom, dateTo, userId, vehicleRegistration, paymentType])

  if (receiptIsLoading || !receiptData) {
    return <PageLoader />
  }

  const handleRouteToReceiptDetails = (id: number) =>
    history.push(`/transactions/receipt/${id}`)

  const handleRouteToPaymentDetails = (id: number) =>
    history.push(`/transactions/payment/${id}`)

  return (
    <div tw='mt-4'>
      <div tw='flex flex-row w-full justify-start px-4 gap-x-8'>
        <FormProvider {...methods} control={methods.control}>
          <div tw='w-32'>
            <SelectField
              name='type'
              label='Transaction type'
              options={Object.values(TransactionType).map((o) => ({
                value: o,
                label: o,
              }))}
            />{' '}
          </div>

          <div tw='w-64'>
            <DateInput
              startDateName='dateFrom'
              endDateName='dateTo'
              label='Date'
            />{' '}
          </div>

          {((type === TransactionType.RECEIPT && !vehicleRegistration) ||
            type === TransactionType.PAYMENT_ORDER) && (
            <div tw='w-48'>
              <SelectField
                name='userId'
                label='User'
                isSearchable={true}
                options={
                  receiptUsersData
                    ? receiptUsersData.map((u) => ({
                        value: u.id,
                        label: u.name,
                      }))
                    : []
                }
              />{' '}
            </div>
          )}
          {type === TransactionType.RECEIPT && !userId && (
            <div tw='w-48'>
              <SelectField
                name='vehicleRegistration'
                label='Vehicle'
                isSearchable={true}
                options={
                  receiptVehiclesData
                    ? receiptVehiclesData.map((v) => ({
                        value: v.registration,
                        label: v.registration,
                      }))
                    : []
                }
              />
            </div>
          )}
          {type === TransactionType.PAYMENT_ORDER && (
            <div tw='w-40'>
              <SelectField
                name='paymentType'
                label='Payment type'
                isSearchable={false}
                options={[
                  { label: 'WSPAY', value: 'WSPAY' },
                  { label: 'MANUAL', value: 'MANUAL' },
                ]}
              />
            </div>
          )}
        </FormProvider>
      </div>
      {type === TransactionType.RECEIPT ? (
        <CustomTable
          loading={receiptIsLoading}
          maxPage={receiptData.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={receiptData.items}
          totalCount={receiptData.count}
          onRowClick={(t) => handleRouteToReceiptDetails(t.id)}
          columns={[
            {
              Header: 'User',
              accessor: 'ownerName',
              cell: (l) => <TableCell.Text value={`${l.ownerName}`} />,
            },
            {
              Header: 'Transaction date',
              accessor: 'date',
              cell: (l) => (
                <TableCell.Text
                  value={l.date ? standardizedFormatDate(l.date) : '-'}
                />
              ),
            },
            {
              Header: 'Amount',
              accessor: 'amount',
              cell: (l) => {
                return (
                  <TableCell.Text
                    value={`${l.amount.toFixed(2)} EUR (${convertEURToHRK(
                      parseFloat(l.amount.toFixed(2)),
                    )} HRK)`}
                  />
                )
              },
            },
            {
              Header: 'Type',
              accessor: 'type',
              cell: (l) => (
                <TableCell.Text
                  value={`${l.type === 'PREPAID' ? 'WSPAY' : 'MANUAL'}`}
                />
              ),
            },
          ]}
        />
      ) : (
        //TODO: Adjust types for payment orders
        <CustomTable
          loading={paymentIsLoading}
          maxPage={paymentData.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={paymentData.items}
          totalCount={paymentData.count}
          onRowClick={(t) => handleRouteToPaymentDetails(t.id)}
          columns={[
            {
              Header: 'User',
              accessor: 'ownerName',
              cell: (l) => <TableCell.Text value={`${l.ownerName}`} />,
            },
            {
              Header: 'Transaction date',
              accessor: 'date',
              cell: (l) => (
                <TableCell.Text
                  value={l.date ? standardizedFormatDate(l.date) : '-'}
                />
              ),
            },
            {
              Header: 'Amount',
              accessor: 'amount',
              cell: (l) => {
                return (
                  <TableCell.Text
                    //@ts-ignore
                    value={`${l.amount} EUR`}
                  />
                )
              },
            },
            {
              Header: 'Type',
              accessor: 'type',
              cell: (l) => (
                <TableCell.Text
                  value={`${l.type === 'PREPAID' ? 'MANUAL' : 'WSPAY'}`}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  )
}
