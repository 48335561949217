/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useMemo } from 'react'

import dayjs from 'dayjs'
import { Receipt, ReceiptItem } from '../../../../api-types'
import { convertEURToHRK, getTimeDiffFormatted } from '../../../../utils'
import { CardWithHeader } from '../../../common/general/CardWithHeader'
import { Field } from '../../../common/general/Field'
import { uniq } from 'lodash'

interface IReceiptDetailsSectionProps {
  receipt: Receipt
}

export const ReceiptDetailsSection = (props: IReceiptDetailsSectionProps) => {
  const { receipt } = props

  const formattedDuration = getTimeDiffFormatted(
    receipt.periodStartDate,
    receipt.periodEndDate,
  )

  const onlyOneVehicle =
    uniq(receipt.items.map((item: ReceiptItem) => item.vehicleRegistration))
      .length === 1

  const total = useMemo(() => {
    if (receipt && receipt.items) {
      return receipt.items.reduce(
        (sum: number, item: any) => item.consumption * item.tariff + sum,
        0,
      )
    }
    return 0
  }, [receipt])

  return (
    <CardWithHeader tw='mb-6' title='Transaction Details'>
      <div tw='flex flex-col space-y-8'>
        <Field
          tw='flex-1'
          label='Transaction Identifier'
          value={receipt.transactionId}
        />
        <div tw='flex flex-row'>
          <Field
            tw='flex-1'
            label='Start date'
            value={dayjs(receipt.periodStartDate).format('DD.MM.YYYY. HH:mm')}
          />
          <Field
            tw='flex-1'
            label='End date'
            value={dayjs(receipt.periodEndDate).format('DD.MM.YYYY. HH:mm')}
          />
        </div>
        <div tw='flex flex-row'>
          <Field
            tw='flex-1'
            label='Location'
            value={
              receipt?.account.location ? receipt.account.location.name : ''
            }
          />
          {onlyOneVehicle && (
            <Field
              tw='flex-1'
              label='Vehicle'
              value={receipt.items[0].vehicleRegistration ?? ''}
            />
          )}
        </div>
        <div tw='flex flex-row'>
          <Field tw='flex-1' label='Duration' value={formattedDuration} />
          <Field
            tw='flex-1'
            label='Total'
            value={`${total.toFixed(2)} EUR (${convertEURToHRK(total).toFixed(
              2,
            )} HRK)`}
          />{' '}
        </div>
        {/* TODO: receipt item cards */}
      </div>
    </CardWithHeader>
  )
}
