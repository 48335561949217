/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useParams } from 'react-router-dom'
import { PageLoader } from '../../../common/general/PageLoader'
import { ContentContainer } from '../../../common/general/ContentContainer'
import { useReceipt } from '../../../../queries'
import { Breadcrumbs } from '../../../common/general/Breadcrumbs'
import { ReceiptDetailsSection } from './ReceiptDetailsSection'
import { ReceiptItemDetails } from './ReceiptItemDetails'

export const ReceiptDetailsPage = (): JSX.Element => {
  const { receiptId } = useParams<{ receiptId: string }>()
  const {
    data: receipt,
    isError,
    isLoading,
    isRefetching,
  } = useReceipt(receiptId)

  if (isLoading || isRefetching) {
    return <PageLoader />
  }
  if (isError || !receipt) {
    return <p>Transaction does not exist!</p>
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Transactions', route: '/transactions/table' },
          { name: 'Transaction details' },
        ]}
      />
      <ReceiptDetailsSection receipt={receipt} />
      <div tw='flex flex-col gap-y-4'>
        {receipt.items.map((i) => (
          <ReceiptItemDetails receiptItem={i} />
        ))}
      </div>
    </ContentContainer>
  )
}
