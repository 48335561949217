/** @jsxImportSource @emotion/react */
import 'twin.macro'

import dayjs from 'dayjs'
import { PaymentOrder } from '../../../../api-types'
import { convertEURToHRK } from '../../../../utils'
import { CardWithHeader } from '../../../common/general/CardWithHeader'
import { Field } from '../../../common/general/Field'
import { Button } from '../../../common/general/Button'
import { http } from '../../../../http'

interface IPaymentDetailsSectionProps {
  payment: PaymentOrder
}

export const PaymentDetailsSection = (props: IPaymentDetailsSectionProps) => {
  const { payment } = props

  const downloadInvoicePdf = async () => {
    try {
      const response = await http.getInvoicePdf(payment.id)
      const blob = response.data

      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', `invoice_${payment.id}.pdf`)

      document.body.appendChild(link)

      link.click()

      link.remove()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading the invoice:', error)
    }
  }

  return (
    <CardWithHeader tw='mb-6' title='Transaction Details'>
      <div tw='flex flex-col space-y-8'>
        <div tw='flex flex-row'>
          <Field
            tw='flex-1'
            label='Amount'
            value={`${parseFloat(payment.amount).toFixed(
              2,
            )} EUR (${convertEURToHRK(parseFloat(payment.amount))} HRK)`}
          />
          <Field
            tw='flex-1'
            label='Date'
            value={dayjs(
              payment.invoiceDate ? payment.invoiceDate : payment.createdAt,
            ).format('DD.MM.YYYY. HH:mm')}
          />
        </div>
        {payment.shopId !== 'PREPAID' && payment.shopId !== 'POSTPAID' && (
          <Button
            text={'Download invoice PDF'}
            onClick={() => downloadInvoicePdf()}
          />
        )}
      </div>
    </CardWithHeader>
  )
}
