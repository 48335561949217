import dayjs from 'dayjs'
import { PhaseTypeEnum } from '../../../../api-types'
import { Granularity } from './report-enums'
import { ReportSearchParams } from './report-search-params-types'

export type ReportDataItem = {
  label: Date
  count: number
}

export type ReportItem = {
  key: string
  data: ReportDataItem[]
  total: number
  unit: string
  color: string
}

export type Report = ReportItem[]

export type ReportConsumptionTableData = {
  totalConsumptionValve: number
  totalAmountValve: number
  totalConsumptionSocket: number
  totalAmountSocket: number
  totalAmount: number
}

// * Initial report filters

export const initialReportFilters: ReportSearchParams = {
  granularity: Granularity.DAY,
  from: dayjs().subtract(1, 'month').toDate(),
  to: dayjs().toDate(),
}

// * Socket report

export type SocketReport = {
  connectionSize: string
  phase: PhaseTypeEnum
  current: number
  numberOfSessions: number
  totalConsumption: number
}

// * Transactions table data

export type Transaction = {
  id: number
  ownerName: string
  ownerId: number
  date: string
  amount: number
  type: string
}
