import { UseQueryResult } from 'react-query'
import plugin from 'dayjs/plugin/duration'
import { showErrorNotification } from './pages/common/general/notification'
import dayjs from 'dayjs'

export const mapErrorToCode = (e: any): number => {
  return e?.response?.status ?? 500
}

type UseQuerySearchResult<T> = Omit<UseQueryResult<T>, 'data'> & { data: T }
export type Maybe<T> = T | null

export const transformQueryResponseData = <T,>({
  data,
  ...rest
}: any): UseQuerySearchResult<T> => {
  //@ts-ignore
  const d = data ?? {}
  return {
    ...rest,
    data: {
      ...d,
      items: d.items ?? [],
      totalPages: d.totalPages ?? 0,
      count: d.count ?? 0,
      hasMore: d.hasMore ?? false,
      limits: d.limits ?? {},
    } as T,
  }
}

export const formatFraction = (first: number, second: number) => {
  return (
    <span>
      <sup>{first}</sup>
      <span className='fraction-divider'>&frasl;</span>
      <sub>{second}</sub>
    </span>
  )
}

export const MOBILE_BREAKPOINT = 800
export const isMobile = window.screen.width < MOBILE_BREAKPOINT

export const areObjectEqualShallow = (a: any, b: any) => {
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false
    }
  }
  for (const key in b) {
    if (!(key in a)) {
      return false
    }
  }
  return true
}

export const countDifferentValuesShallow = (a: any, b: any) => {
  let count = 0
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      count++
    }
  }
  for (const key in b) {
    if (!(key in a)) {
      count++
    }
  }
  return count
}

export const getDurationFormat = (duration: plugin.Duration): string => {
  if (duration.asYears() > 1) return 'Y[y] M[m]'
  if (duration.asMonths() > 1) return 'M[m] D[d]'
  if (duration.asDays() > 1) return 'D[d] H[h]'
  if (duration.asHours() > 1) return 'HH[h]:mm[min]'
  return 'mm [min]:ss [sec]'
}

export const parseBackendException = (exception: any) => {
  let message
  try {
    const result = JSON.parse(exception?.request?.response)
    message = result.message
  } catch (e) {
    message = 'Something went wrong!'
  } finally {
    showErrorNotification(message)
  }
}

export const roundToFloatWith3Decimals = (value: string | number) => {
  return parseFloat(parseFloat(value.toString()).toFixed(3))
}

export const roundToFloatWithExactlyNDecimals = (
  value: string | number,
  numOfDecimals: number,
) => {
  return parseFloat(value.toString()).toFixed(numOfDecimals)
}

export const capitalizeString = (string: string) => {
  return (
    string.toLowerCase().charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  )
}

export const standardizedFormatDate = (date: Date | string) => {
  return dayjs(date).format('DD/MM/YYYY HH:mm')
}

const EURO_HRK_FIXED_CONVERSION_RATE = 7.5345

export const convertEURToHRK = (euros: number): number =>
  parseFloat((euros * EURO_HRK_FIXED_CONVERSION_RATE).toFixed(3))

export const getTimeDiffFormatted = (
  startDate: dayjs.Dayjs | string | Date,
  endDate: dayjs.Dayjs | string | Date,
): string => {
  const diff = dayjs(endDate).diff(dayjs(startDate))
  const duration = dayjs.duration(diff)
  const formattedDuration = duration.format(getDurationFormat(duration))
  return formattedDuration
}
