import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { omit, pick } from 'lodash'
import { useEffect } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { http } from '../../../../http'
import { useReport, useReportTable } from '../../../../queries'
import { csvExporter } from '../../../../services/csv-exporter'
import { useSearchQueryParams } from '../../general/hooks/useSearchQueryParams'
import { showErrorNotification } from '../../general/notification'
import { PageLoader } from '../../general/PageLoader'
import { Txt } from '../../general/Txt'
import { ReportLabelOptions, ReportType } from '../report-types/report-enums'
import {
  GenericReportSearchParams,
  ReportSearchParams,
} from '../report-types/report-search-params-types'
import {
  FilterOption,
  ReportFilterHeader,
} from './report-filter-header-components/ReportFilterHeader'
import { ReportHeader } from './report-header-components/ReportHeader'
import { ReportTransactionsTable } from './report-table-components/ReportTransactionsTable'

interface ReportProps<T> {
  reportType: ReportType
  filters: T
  setFilters: (filters: T) => void
  filtersOptions: Array<FilterOption<Omit<T, keyof ReportSearchParams>>>
  isLoading?: boolean
  isError?: Error | AxiosError | boolean | null
}

// TODO - better handling of errors
export const ReportComponent = <TFilters extends ReportSearchParams>(
  props: ReportProps<TFilters>,
) => {
  const {
    reportType,
    filters,
    setFilters,
    filtersOptions,
    isLoading = false,
    isError = false,
  } = props

  //#region Search query params handling
  const [, setSearchParams] = useSearchQueryParams<TFilters>()
  //#endregion

  //#region Fetching report
  const {
    data: report,
    error: reportError,
    isLoading: isLoadingReport,
    refetch: refetchReport,
    isRefetching: isRefetchingReport,
  } = useReport<GenericReportSearchParams[typeof reportType]>(
    reportType,
    filters,
  )

  //TODO: Refactor this depending on the other report tables requirements
  const {
    data: reportTable,
    error: reportTableError,
    isLoading: isLoadingReportTable,
    refetch: refetchReportTable,
    isRefetching: isRefetchingReportTable,
  } = useReportTable<GenericReportSearchParams[typeof reportType]>(
    reportType,
    filters,
  )
  //#endregion

  //#region Hooks for refetching data
  useEffect(() => {
    refetchReport()
    refetchReportTable()
    setSearchParams(filters)
  }, [filters])
  //#endregion

  //#region Functions
  const onDownloadCsv = async () => {
    const data = await http.getReportExportData(
      reportType,
      omit(filters, 'granularity'),
    )

    if (data.length > 0) {
      csvExporter.generateAndDownload(data, filters, reportType)
    } else {
      showErrorNotification('No data to export')
    }
  }
  const onPaddingChange = (newPadding: ReportSearchParams) => {
    setFilters({
      ...filters,
      ...newPadding,
    })
  }
  const onFiltersChange = (
    newFilters: Omit<TFilters, keyof ReportSearchParams>,
  ) => {
    setFilters({
      ...filters,
      ...newFilters,
    })
  }
  //#endregion

  return (
    <>
      <ReportHeader
        filters={pick(filters, ['granularity', 'from', 'to'])}
        setFilters={onPaddingChange}
        report={report}
      />
      <div tw='h-px bg-primary-500 opacity-25 mx-5 my-5' />
      <ReportFilterHeader
        filters={omit(filters, ['granularity', 'from', 'to'])}
        setFilters={onFiltersChange}
        filtersOptions={filtersOptions}
        onDownloadCsv={onDownloadCsv}
      />
      {isLoading || isLoadingReport || isRefetchingReport ? (
        <PageLoader />
      ) : isError || reportError ? (
        <Txt tw='text-center'>Some error occurred</Txt>
      ) : !report ? (
        <Txt tw='text-center'>No data found</Txt>
      ) : (
        report.map((dataItem) => (
          <ResponsiveContainer width='100%' height={400} key={dataItem.key}>
            <AreaChart
              data={dataItem.data}
              margin={{ top: 0, right: 50, left: 30, bottom: 30 }}
            >
              <defs>
                <linearGradient id={dataItem.key} x1='0' y1='0' x2='0' y2='1'>
                  <stop
                    offset='5%'
                    stopColor={dataItem.color}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset='195%'
                    stopColor={dataItem.color}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <XAxis
                dataKey='label'
                tickMargin={20}
                minTickGap={25}
                interval={'preserveStart'}
                tickFormatter={(label) =>
                  dayjs(label).format(
                    ReportLabelOptions[
                      filters.granularity.toUpperCase() as keyof typeof ReportLabelOptions
                    ],
                  )
                }
              />
              <YAxis tickMargin={20} />
              <CartesianGrid />
              <Tooltip
                labelFormatter={(label) => dayjs(label).format('DD/MM/YYYY')}
              />
              <Area
                type='monotone'
                dataKey='count'
                stroke={dataItem.color}
                fillOpacity={1}
                fill={`url(#${dataItem.key})`}
              />
            </AreaChart>
          </ResponsiveContainer>
        ))
      )}
      {reportType === ReportType.TRANSACTIONS ? (
        isLoading || isLoadingReportTable || isRefetchingReportTable ? (
          <PageLoader />
        ) : isError || reportTableError ? (
          <Txt tw='text-center'>Some error occurred</Txt>
        ) : !reportTable ? (
          <Txt tw='text-center'>No data found</Txt>
        ) : (
          <ReportTransactionsTable data={reportTable} />
        )
      ) : (
        <div></div>
      )}
    </>
  )
}
