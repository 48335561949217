/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useState } from 'react'
import { useGetAllUsers, useGetAllVehicles } from '../../../../queries'
import { adminRoutes } from '../../general/route-names'
import { ActionBar } from '../../general/ActionBar'
import { Breadcrumbs } from '../../general/Breadcrumbs'
import { ContentContainer } from '../../general/ContentContainer'
import { PageLoader } from '../../general/PageLoader'
import { isEmpty, omitBy } from 'lodash'
import { ReportComponent } from '../report-components/ReportComponent'
import { useSearchQueryParams } from '../../general/hooks/useSearchQueryParams'
import { PaymentTypeEnum, ReceiptStatus } from '../../../../api-types'
import { ReportType } from '../report-types/report-enums'
import { ISearchParams } from '../../../../types'
import { TransactionsReportSearchParams } from '../report-types/report-search-params-types'
import { initialReportFilters } from '../report-types/report-types'

export const TransactionsReportPage = () => {
  const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams<
    TransactionsReportSearchParams & ISearchParams
  >()

  const [filters, setFilters] = useState<TransactionsReportSearchParams>(
    !isEmpty(searchQueryParams)
      ? (omitBy(searchQueryParams, [
          'page',
          'term',
          'size',
          'order',
          'orderField',
        ]) as TransactionsReportSearchParams)
      : initialReportFilters,
  )

  //#region Fetching data
  const {
    data: users,
    error: usersError,
    isLoading: isLoadingUsers,
  } = useGetAllUsers()
  const {
    data: vehicles,
    error: vehiclesError,
    isLoading: isLoadingVehicles,
  } = useGetAllVehicles()

  //#endregion

  if (isLoadingUsers || isLoadingVehicles) {
    return <PageLoader />
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Reports', route: adminRoutes.REPORTS },
          { name: 'Transactions' },
        ]}
      />
      <ActionBar title='Transactions report' tw='mb-5' />
      <ReportComponent
        reportType={ReportType.TRANSACTIONS}
        filters={filters}
        setFilters={setFilters}
        filtersOptions={[
          {
            label: 'Status',
            filterKey: 'receiptStatus',
            options: [
              {
                label: 'Paid',
                value: ReceiptStatus.PAID,
              },
              {
                label: 'Unpaid',
                value: ReceiptStatus.UNPAID,
              },
            ],
          },
          {
            label: 'User',
            filterKey: 'userId',
            options: users
              ? users.map((val) => ({
                  label: val.name,
                  value: val.id,
                }))
              : [],
            searchable: true,
            disablesFilters: ['vehicleId'],
          },
          {
            label: 'Vehicle',
            filterKey: 'vehicleId',
            options: vehicles
              ? vehicles.map((vehicle) => ({
                  label: vehicle.registration,
                  value: vehicle.id,
                }))
              : [],
            searchable: true,
            disablesFilters: ['userId'],
          },
          {
            label: 'Payment Type',
            filterKey: 'paymentType',
            options: [
              {
                label: 'Prepaid',
                value: PaymentTypeEnum.PREPAID,
              },
              {
                label: 'Postpaid',
                value: PaymentTypeEnum.POSTPAID,
              },
              {
                label: 'Periodical',
                value: PaymentTypeEnum.PERIODICAL,
              },
            ],
          },
        ]}
        isError={usersError || vehiclesError}
      />
    </ContentContainer>
  )
}
