/** @jsxImportSource @emotion/react */
import 'twin.macro'
import {
  PhaseTypeEnum,
  ReceiptItem,
  SourceTypeEnum,
} from '../../../../api-types'
import { Field } from '../../../common/general/Field'
import { Txt } from '../../../common/general/Txt'
import { convertEURToHRK, getTimeDiffFormatted } from '../../../../utils'
import { SocketIcon, ValveIcon } from '../../../common/general/assets'
import { css } from '@emotion/css'

interface IReceiptItemDetailsProps {
  receiptItem: ReceiptItem
}

export const ReceiptItemDetails = (props: IReceiptItemDetailsProps) => {
  const { receiptItem } = props

  return (
    <div tw='flex flex-col space-y-8 w-1/2 bg-gray-200 p-4'>
      <div tw='flex flex-row justify-between'>
        <div tw='flex flex-col gap-y-0.5'>
          <Txt md tw='text-gray-500'>
            {`ID: ${receiptItem.pedestalIdentifier}`}
          </Txt>
          <Txt xl tw='uppercase'>
            {receiptItem.service}
          </Txt>
        </div>
        {receiptItem.sourceType === SourceTypeEnum.SOCKET ? (
          receiptItem.phaseType === PhaseTypeEnum.MONO_PHASE ? (
            <SocketIcon
              className={css`
                > path {
                  fill: #1684dd;
                }
              `}
            />
          ) : (
            <SocketIcon
              className={css`
                > path {
                  fill: #de373d;
                }
              `}
            />
          )
        ) : (
          <ValveIcon
            className={css`
              > path {
                fill: #1d5883;
              }
            `}
          />
        )}
      </div>
      <Field
        tw='flex-1'
        label='Consumption'
        value={
          receiptItem.sourceType === SourceTypeEnum.VALVE
            ? `${receiptItem.consumption.toFixed(3)} m\u00B3`
            : `${receiptItem.consumption.toFixed(3)} kWh`
        }
      />
      <div tw='flex flex-row'>
        <Field
          tw='flex-1'
          label='Duration'
          value={getTimeDiffFormatted(
            receiptItem.startDate,
            receiptItem.endDate,
          )}
        />
        <Field
          tw='flex-1'
          label='Total'
          value={`${(receiptItem.consumption * receiptItem.tariff).toFixed(
            2,
          )} EUR (${convertEURToHRK(
            receiptItem.consumption * receiptItem.tariff,
          )} HRK)`}
        />
      </div>
    </div>
  )
}
