/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ReportConsumptionTableData } from '../../report-types/report-types'

interface ReportTransactionsTableProps {
  data: ReportConsumptionTableData
}

export const ReportTransactionsTable = (
  props: ReportTransactionsTableProps,
) => {
  const { data } = props

  return (
    <table tw='min-w-full text-xl my-10'>
      <tbody>
        <tr tw='border'>
          <td tw='px-4 py-2 text-left'>Total valve consumption</td>
          <td tw='px-4 py-2 text-right'>
            {data.totalConsumptionValve.toFixed(5)} m³{' '}
          </td>
        </tr>
        <tr tw='border'>
          <td tw='px-4 py-2 text-left'>Total valve amount</td>
          <td tw='px-4 py-2 text-right'>
            {data.totalAmountValve.toFixed(2)} €
          </td>
        </tr>
        <tr tw='border'>
          <td tw='px-4 py-2 text-left'>Total socket consumption</td>
          <td tw='px-4 py-2 text-right'>
            {data.totalConsumptionSocket.toFixed(5)} kWh
          </td>
        </tr>
        <tr tw='border'>
          <td tw='px-4 py-2 text-left'>Total socket amount</td>
          <td tw='px-4 py-2 text-right'>
            {data.totalAmountSocket.toFixed(2)} €
          </td>
        </tr>
        <tr tw='border'>
          <td tw='px-4 py-2 text-left'>Total amount</td>
          <td tw='px-4 py-2 text-right'>{data.totalAmount.toFixed(2)} €</td>
        </tr>
      </tbody>
    </table>
  )
}
