/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useParams } from 'react-router-dom'
import { PageLoader } from '../../../common/general/PageLoader'
import { ContentContainer } from '../../../common/general/ContentContainer'
import { usePaymentOrder } from '../../../../queries'
import { Breadcrumbs } from '../../../common/general/Breadcrumbs'
import { PaymentDetailsSection } from './PaymentDetailsSection'

export const PaymentDetailsPage = (): JSX.Element => {
  const { paymentId } = useParams<{ paymentId: string }>()
  const {
    data: payment,
    isError,
    isLoading,
    isRefetching,
  } = usePaymentOrder(paymentId)

  if (isLoading || isRefetching) {
    return <PageLoader />
  }
  if (isError || !payment) {
    return <p>Transaction does not exist!</p>
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Transactions', route: '/transactions/table' },
          { name: 'Transaction details' },
        ]}
      />
      <PaymentDetailsSection payment={payment} />
    </ContentContainer>
  )
}
